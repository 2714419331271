/* Order summary */

.your-order{
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border-bottom: 5px solid #2fac66;
  padding: 34px 40px;
  margin-bottom: 35px;
  &.your-order-mobile{
   display: none;
  }

  @media screen and (max-width: 991px){
    padding: 20px 15px;
  }

  @media screen and (max-width: 767px){
    &.your-order-mobile{
      display: block;
    }
    &.your-order-desktop{
      display: none;
    }
  }

  &__data-container{
    border-bottom: 1px solid #ede7de;
    padding-bottom: 15px;
  }

  dl{
    margin-bottom: 0;
  }

  &__title{
    color: #4a4032;
    font-family: 'Muli', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    margin-top: 0;
    margin-bottom: 25px;
  }

  &__key{
    color: #4a403280;
    font-family: Muli, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    min-height: 30px;
    display: flex;
    align-items:center;
    justify-content:flex-start;
  }

  &__value{
    color: #4a403280;
    font-family: Muli, sans-serif;
    font-size: 14px;
    font-weight: 700;
    min-height: 30px;
    display: flex;
    align-items:center;
    justify-content:flex-start;

  }
  &__summary-data-container{
    padding-top: 10px;
  }

  &__summary-key{
    color: #4a4032;
    font-family: Muli, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
  }

  &__summary-value{
    color: #2aa23f;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    font-family: "Centrale Sans Rounded";
  }

  &__footer{
    text-align:center;
  }

  &__footer-title{
    color: #4a4032;
    font-family: Muli, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 15px;
  }

  &__footer-contact-number{
    color: #4a4032;
    font-family: Muli, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height:19px;

    display:inline-flex;
    align-items:center;
    justify-content: flex-start;
  }


  &-phone-icon{
    height: 19px;
    width: 19px;
    margin-right: 10px;
    display: inline-block;

    background-image: url(../../img/phone-icon.svg);
    background-position:center;
    -webkit-background-size: contain;
    background-size: contain;
  }
}
