#logged-info-box {
  padding-bottom: 42px;
  margin-bottom: 42px;
  border-bottom: 1px solid #f6f5f5;

  .order-items-container{
    position: relative;
    display: flex;
    align-items:center;
    justify-content:center;
  }

  .order-name-lastname{
    display: block;
    width:auto;

    color: #4a4032;
    font-family: Muli, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
  }

  .order-logout{
    position: absolute;
    top:50%;
    right:0;
    transform:translateY(-50%);

    a{
      color: #c2beba;
      font-family: "Quicksand";
      font-size: 14px;
      font-weight: 900;
      transition: color 0.3s;

      &:hover{
        color: #515151;
      }
    }
  }
}