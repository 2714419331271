.delivery_options{
  //margin-bottom: 40px;
}

.delivery_option{
  max-width: 288px;

  >label{
    >div{
      width:100%;
    }
  }

  .table{
    margin-top: 0;
    margin-bottom: 0;
    tbody{
      > tr{
        > td{
          border-bottom: none;
          padding: 0;
          padding-bottom: 7px;
        }
      }
    }
  }

  &_label{
    display: flex;
    align-items:center;
    justify-content: flex-start;

    cursor:pointer;
  }

  &_input{
    flex-shrink:1;
    margin-right: 16px;
    font-weight: 400;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width:20px;

    .checkbox-icon{
      width:20px;
      height:20px;
      margin: 0;
      padding: 0;
      display: inline-block;
      font-weight: normal;

      &:after{
        content: '\f096';
        font-family: 'FontAwesome';
        font-size: 22px;
        color: #8c8a87;
        visibility: visible;
        line-height: 22px;
      }
    }

    input:checked + .checkbox-icon{
      &:after{
        content: '\f046';
      }
    }

    input{
      display: none;
    }

  }

  &_data{
    display: flex;
    align-items:center;
    justify-content:space-between;
    flex-grow: 1;

    .name{
      color: #4a4032;
      font-family: Muli, sans-serif;
      font-size: 14px;
      font-weight: 400;
    }

    .delivery_option_price{
      color: #4a4032;
      font-family: Muli, sans-serif;
      font-size: 14px;
      font-weight: 700;
    }
  }

  //&_radio{
  //  width: 50px;
  //  margin-right: 15px;
  //}
  //
  &_name{
    @media (max-width: 768px){
      width: 200px;
    }
  }

  .delivery_option_carrier_place{
    font-size: 10px;
  }

  .delivery_option_name{
    @media screen and (min-width: 767px){
      min-width: 190px;
    }
  }

  .delivery_option_price{
    white-space: nowrap;
    padding-right: 0;
  }
}