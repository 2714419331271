/* Rabaty */
#cart_voucher{
  padding-top:33px;
  padding-left: 40px;
  padding-bottom: 40px;
}
.cart_voucher{
  padding-top: 33px;

  fieldset #discount_name{
    height: 50px;
    background-color:#f6f5f5;
    border:none;
    margin-right:0;
    border-right:1px solid #4a40321a;
    padding: 5px 26px;

    &::-webkit-input-placeholder{
      color: rgba(74, 64, 50, 0.5);
    }

    @media screen and (max-width: 991px){
      width: 160px;
    }

    @media screen and (max-width: 767px){
      width: calc(100% - 116px);
    }

  }
}

#voucher{
  @media screen and (max-width: 767px){
    width: 100%;
  }

  button{
    height:50px;
    width: 116px;
    background-color:#f6f5f5;
    color:black;
    border:none;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
  }

  button:hover{
    background-color: #e5e5e5;
  }

  button span{
    border: none;
    text-shadow: none;
    color: #4a4032;
    font-family: Muli, sans-serif;
    font-size: 14px;font-weight: 700;
    line-height: 18px;
  }

  button span:hover{
    background-color:transparent;
  }
}


