/* Charity input style */

.charity_input{
  width:20px;
  height:20px;
  margin: 0;
  padding: 0;
  display: inline-block;
}

input.charity_input{
  margin: 0;
  padding: 0;
  cursor:pointer;

}

.charity_label{
  display: inline-block;
  line-height:20px;
  margin-left: 0px;

  opacity: 1;
  color: #4a4032;
  font-family: Muli, sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: opacity 0.3s;
  
}

.charity_input_container{
  display: inline-flex;
  align-items:flex-start;
  justify-content: flex-start;
  cursor:pointer;
}

.charity_input_container:hover .charity_label, .charity_input:checked + .charity_label{
  opacity: 1;
}



.heart-checkbox{

  &__label{
    color: #2aa23f;
  }

  &__input:checked + &__icon{
    &::before{
      background-image: url('../../img/heart-filled.svg');
    }
  }

  &__icon{
    margin-right: 10px;
    &::before{
      content: "";
      display: inline-block;

      background-image: url('../../img/heart-hollow.svg');
      -webkit-background-size: contain;
      background-size: contain;
      background-position: center;

      background-repeat:no-repeat;

      width: 20px;
      height: 20px;
    }

    &.visual{
      opacity: 0;
      height: 24px;
      width: 20px!important;
      margin-right: 0;
    }
    &.visual.filled{
      opacity: 1;
      &::before {
        background-image: url('../../img/heart-filled.svg');
      }
    }
  }
  &__value {
    color: #42AD55!important;
    font-weight: 900!important;
    line-height: 24px;
    vertical-align: super;
    display: inline-block;
    margin-left: 5px;
  }
}