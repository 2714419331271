/* Checkout changes */


#order-detail-content{
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ede7de;

  @media screen and (max-width: 767px){
    overflow:visible;
    padding-bottom: 0;
  }
}

#cart_title{
  text-align: center;
  text-transform:none;
  color: #4a4032;
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
  border-bottom: none;
  margin-bottom: 23px;
  padding-bottom: 0;
}

#order-opc #columns.container{

  padding-left: 30px;
  padding-right: 30px;

  @media screen and (min-width: 1200px){
    padding-left: 110px;
    padding-right: 110px;
  }
}
#order-detail-content{

}
#cart_summary{
  margin-top: 0;
  padding-bottom: 24px;

     td{
       @media screen and (max-width: 768px){
          display: block;
          width: 100%;
          text-align:center;
       }
     }

  thead th{
    padding: 7px 8px;
    color: #4a4032;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }

  .product-name a {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.06px;
    text-transform:uppercase;
  }

  .product-description a {
    color: #4a4032;
    font-family: "Centrale Sans Rounded";
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }

  .cart_quantity{
    @media screen and (max-width: 768px){
      display:flex;
      align-items:center;
      justify-content: center;
      flex-wrap:wrap;

      &:before{
        width:100%;
      }
    }

    .cart_quantity_input{
      float:left;
    }
  }

  .cart_quantity_button {
    margin-top: 0px;
    float: left;
    width: 27px;
    height: 50px;
    background-color: #f6f5f5;
    border-radius: 4px 0 0 4px;

    .btn{
      border:none;
      background-color: transparent;
      margin-right: 0px;
      transition: color 0.3s;
    }

    .btn:hover{
      color: #333;
    }

    .btn.button-plus span,
    .btn.button-minus span{
      background: none;
      border:none;

      padding:0;
      width:27px;
      height:27px;
      display: flex;
      align-items:center;
      justify-content:center;
    }
  }

  tbody .cart_weight a,
  tbody .cart_total .price,
  #total_price_container{
    color: #2aa23f;
    font-family: "Centrale Sans Rounded";
    font-size: 18px;
    font-weight: 900;
    white-space:nowrap;
    letter-spacing: 0.09px;
  }

  tbody .cart_weight a{
    font-weight: 700;
  }

  tfoot td#total_price_container{
    font-weight: 900;

  }

  tr.last_item td{
    border-bottom: 5px solid #c2c2c2;
  }

  tr.last_item .cart_total, tr.last_item .cart_weight, tr.last_item .cart_delete{
    border-bottom-color: #2fac66;
  }

  tfoot{
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    padding-top: 33px;
  }

  .total_price_container,
  #total_price_container,
  .cart_total_delivery td,
  .cart_total_price .cart_discount{
    padding: 0;
    padding-bottom:5px;
    border:none;
  }

  .cart_total_price .cart_discount{
    padding-top: 33px;
  }

  .cart_total_price .total_price_container,
  .cart_total_delivery .cart_total_delivery_title,
  .cart_total_price .cart_discount_title,
  .cart_total_delivery .cart_total_delife{
    padding-right:24px;
    white-space:nowrap;
  }

  .cart_total_price .total_price_container,
  #total_price_container{
    padding-bottom:24px;
    vertical-align: top;
    line-height:24px;
  }

  .total_price_container span{
    color: #4a4032;
    font-family: Muli, sans-serif;
    font-size: 18px;
    font-weight: 700;
    white-space:nowrap;
  }

  .cart_total_delivery, .cart_discount{
    color: #4a4032;
    font-family: Muli, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }

  tfoot tr th{
    padding:0;
  }

  tbody .cart_total{
    padding-left: 0;
  }

  .cart_rule_comment {
    margin: 20px 0;
  }
}



#proceed-to-step-two{
  border-radius: 4px;
  background-color: #2aa23f;
  padding: 13px 29px;


  @media screen and (max-width: 768px){
    width: 100%;
    margin-bottom: 15px;
    text-align:center;
  }
}

#proceed-to-step-two span{
  padding: 0;
  font-size: 16px;
}


#step-two{
  margin-top: 50px;
}

.form-legend{
  margin-top: 30px;
}

/* Checkout changes END*/


.total_price_without_reduction{
  font-size: 14px;
  color: #4a4032;
  opacity: 0.5;
  font-weight: 700;
  text-decoration: line-through;
}

.product_price_without_reduction{
 @extend .total_price_without_reduction;
  font-size:12px;
}
