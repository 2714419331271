#step-two{
  #loaded-content{

    .form-group{
      >label{
        display: none;
      }
    }

    .submit2{
      display:flex;
      align-items:center;
      justify-content:space-between;

      padding-bottom: 42px;
      margin-bottom: 42px;
      border-bottom: 1px solid #f6f5f5;

      .btn{
        order:2;
      }

      #close-address-edit{
        cursor:pointer;
      }
    }
  }
}