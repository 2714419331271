/* Login field */
.opc-main-block{

  &__login-container{
    text-align:center;
  }

  &__login-title{
    color: #4a4032;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Quicksand', sans-serif;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__login-text{
    color: #4a4032;
    font-size: 12px;
    font-weight: 300;
    font-family: Muli, sans-serif;
    margin-bottom: 26px;
    display: block;

  }

  &__open-button{
    @extend .normal-button;
    display: block;
    width: 186px;
    //margin-bottom: 90px;

  }
}

#opc_new_account{
  #login_form{
    margin-bottom: 70px;
  }
}
#step-two{
  .form-control {
    max-width: none;
    width: 100%;
    height: auto;
    border-radius: 4px;
    background-color: #f6f5f5;
    border: none;
    padding: 17px 27px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
@media(max-width: 480px){
  #order-opc .cart_navigation {
    text-align: center;
  }
}