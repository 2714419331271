.zoozones{
  display: block;
  color: #2aa23f;
  font-family: "Centrale Sans Rounded";
  font-size: 14px;
  font-weight: 900;
  white-space:nowrap;
  letter-spacing: 0.09px;
  display: flex;
  align-items:center;
  justify-content: flex-start;
  padding-top: 5px;

  @media screen and (max-width: 767px){
    justify-content: center;
  }

  &.row {
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;

    @media (max-width: 767px){
      margin-left: 0;
      margin-right: 0;
    }
  }

  .icon-zoozones{
    padding: 0;
  }

  .zoozones-points{
    display: inline-block;
    white-space: nowrap;
    padding-left:5px;
    line-height:24px;
  }

  .icon-zoozones::before{
    position:static;
    top:0;
    display: inline-block;
  }
}
#block_top_breadcrumb{
  padding-top: 0 !important;
}
#manufacturer{
  #pagination_bottom{
    float: left;
  }
}
#stores-table{
  display: block !important;
  width: 100%;
  margin-bottom: 15px;
  overflow-y: hidden;
  overflow-x: scroll;
  border: 1px solid #d6d4d4;
}
.x13ts_countent{
  background: transparent;
  border: unset;
  margin-bottom: 0;
  padding: unset;
  .x13ts_future{
    padding: 0;
    background: transparent;
    min-height: unset;
    .x13ts_before{
      font-size: unset;
    }
    .x13ts_countdown{
      font-size: unset;
    }
  }
}