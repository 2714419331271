.payment_method{
  display:flex;
  align-items:center;
  justify-content:flex-start;
  margin-bottom: 14px;

  .checkbox-icon{
    width:20px;
    height:20px;
    margin: 0;
    padding: 0;
    display: inline-block;
    font-weight: normal;
    margin-right: 15px;

    &:after{
      content: '\f096';
      font-family: 'FontAwesome';
      font-size: 22px;
      color: #8c8a87;
      visibility: visible;
      line-height: 22px;
    }
  }

  input:checked + .checkbox-icon{
    &:after{
      content: '\f046';
    }
  }

  input{
    display: none;
  }

  label{
    line-height:20px;
    margin-bottom: 0;
    color: #4a4032;
    font-family: Muli, sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
}

#j-created-payments{

}