#addresses-container{
  .address_field{
    color: #4a4032;
    font-family: Muli, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }
  //
  //input{
  //  visibility: hidden;
  //}

  .buttons-container{
    display: flex;
    align-items:center;
    justify-content: flex-end;

    a{
      margin-left: 60px;
      color: #c2beba;
      font-family: "Quicksand";
      font-size: 14px;
      font-weight: 900;
      transition: color 0.3s;
      text-decoration:underline;

      &:hover{
        color: #515151;
      }
    }
  }
}

#delivery-address, #invoice-address{


  label{
    max-width:100%;
  }

  .address-column{
    margin-bottom:15px;
  }

  .address-wrapper{
    display: flex;
    flex-direction:column;
    justify-content:space-between;
    min-height:240px;

    padding: 10px;
    border: 1px solid #d6d4d4;
  }
}

#delivery-address{
  >div{
    width:50%;
  }
}

#id_country{
  //
}