.normal-button {

  //remove button styles
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  // remove button styles end

  display: inline-block;
  margin: auto;
  border-radius: 4px;
  text-shadow: 0 2px 0 #0d7d21;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #2aa23f;

  padding: 15px 40px;

  transition: background-color 0.3s;

  &.small{
    padding: 8px 20px;
    font-size:14px;
  }

  &.medium{
    font-size: 14px;
    padding: 10px 30px;
  }

  &.right {
    float: right;
  }

  &:hover {
    color: white;
    background-color: darken(#2aa23f, 5%);
  }

  &:focus{
    color: white;
  }

  span i.right{
    margin-left: 10px;
    margin-top: 4px;
  }
}



.normal-title {
  color: #4a4032;
  font-size: 18px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;

  &.bottom-offset {
    margin-bottom: 40px;
  }

  &.top-offset {
    margin-top: 40px;
  }
}


.small-title {
  color: #4a4032;
  font-family: Muli, sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;

}

.small-subtitle{
  color: #4a4032;
  font-family: Muli, sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 26px;

  margin-top: 0;
}

#step-one {
 #message{
   padding: 10px;
   height: unset;
   border-radius: 4px;
   background-color: #f6f5f5;
 }
}
#step-two {
  .form-control {
    max-width: none;
    width: 100%;
    height: auto;
    border-radius: 4px;
    background-color: #f6f5f5;
    border: none;
    padding: 17px 27px;
    appearance:none;




    &::placeholder{
      color: #4a403280;
      font-family: Muli, sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
  }
  #message-summary{
    padding: 10px;
    height: unset;
  }
  .select.form-group{
    position: relative;

    &:after{
      content: "";
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: black transparent transparent transparent;
    }

    select::-ms-expand {
      display: none;
    }
  }

  .form-group{
    margin-bottom: 13px;
  }

  .checkbox{

    .checkbox-icon{
      width:20px;
      height:20px;
      margin: 0;
      padding: 0;
      display: inline-block;
      margin-right: 15px;

      &:after{
        content: '\f096';
        font-family: 'FontAwesome';
        font-size: 22px;
        color: #8c8a87;
        visibility: visible;
      }
    }

    input:checked + .checkbox-icon{
      &:after{
        content: '\f046';
      }
    }

    input{
      width:20px;
      height:20px;
      margin: 0;
      padding: 0;
      display: none;
    }

    label{
      line-height:20px;
      color: #4a4032;
      font-family: Muli, sans-serif;
      font-size: 14px;
      font-weight: 400;
      display: inline-flex;
      align-items: flex-start;
      justify-content: flex-start;

    }
  }
}

.form-group{

  &.half-field{
    @media screen and (min-width: 768px){
      width:48%;
      display: inline-block;
    }

    &.half-field-right{
      @media screen and (min-width: 768px) {
        margin-left: 3%;
      }
    }
  }
}

#address{
  .form-group {

    &.half-field {
      @media screen and (min-width: 768px) {
        width: calc(50% - 10px);
        display: inline-block;
      }

      &.half-field-right {
        @media screen and (min-width: 768px) {
          margin-left: 16px;
        }
      }
    }
  }
}

#cgv-container,  .charity_input_container{
  div{
    display: flex;
    align-items:flex-start;
    justify-content:flex-start;

    margin-bottom: 13px;
  }

  .checkbox-icon{
    min-width:20px;
    width:20px;
    height:20px;
    margin: 0;
    padding: 0;
    display: inline-block;
    margin-right: 15px;
    line-height: 23px;
    font-weight: normal;

    &:after{
      content: '\f096';
      font-family: 'FontAwesome';
      font-size: 22px;
      color: #8c8a87;
      visibility: visible;
    }
  }

  input:checked + .checkbox-icon{
    &:after{
      content: '\f046';
    }
  }

  input{
    width:20px;
    height:20px;
    margin: 0;
    padding: 0;
    display: none;
  }

  label{
    line-height:20px;
    margin-bottom: 0;
    color: #4a4032;
    font-family: Muli, sans-serif;
    font-size: 14px;
    font-weight: 400;

    &.checkbox-error{
      color: red;
    }

    a{
      font-weight: 700;
      text-decoration: underline;
    }
  }
}

checkbox-icon-input{
  display: none;
}
.checkbox-icon{
  min-width:20px;
  width:20px;
  height:20px;
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 15px;
  line-height: 23px;
  font-weight: normal;

  &:after{
    content: '\f096';
    font-family: 'FontAwesome';
    font-size: 22px;
    color: #8c8a87;
    visibility: visible;
  }
}

input:checked + .checkbox-icon{
  &:after{
    content: '\f046';
  }
}


.buy-and-pay_button-container{
  width:100%;
  display: flex;
  align-items:center;
  justify-content: center;
}

#buy-and-pay{
  font-weight: 900;

  &.disabled{
    opacity: 0.4;
    cursor: auto;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    &:hover{
      background-color: #2aa23f;
    }
  }
}

.step-two__left-column{
  padding-right: 0;
  padding-left: 0;
  #opc_new_account,
  #carrier_area,
  #j-created-payments,
  .opc-main-block__login-container.offset,
  #opc_new_account #login_form{
    padding-bottom: 42px;
    margin-bottom:42px;
    border-bottom: 1px solid #f6f5f5;
  }

  #cgv-container{
    margin-bottom: 50px;
  }

  .order_carrier_content{
    padding-bottom: 0;
  }
}

.step-two__right-column{
  padding-right: 30px;

  @media screen and (max-width:991px) {
    padding-right: 15px;
  }

  @media screen and (max-width: 768px){
    padding-right: 0;
  }
}



.back-to-step-one-btn{
  color: #c2beba;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 900;
  display: block;

  margin-bottom: 25px;

  transition: color 0.3s;

  .text{
    text-decoration: underline;
  }
}

.right{
  float:right;
}

.special-sub{
  color: #2aa23f;
}

.address_add.submit a{
  @extend .normal-button;
  font-size: 14px;
  padding: 10px 30px;
}

.addressesAreEquals{
  display: none;
}

.fixed-div-in-container{
  -top: 15px;
}


.payment-execution-box{
  padding-left:20px;
  padding-right:20px;

  >div{
    padding-bottom:20px;

  }

}
.payment-execution-heading{
  padding-left:20px;
  padding-right:20px;

}

#forgot_password_modal{
  .submit{
    margin-top: 30px;
  }

}
#forgot_password_modal_form{
  @media screen and (max-width: 767px){
    padding:20px;
  }

  h3{
    @media screen and (max-width: 767px){
      font-size: 16px;
    }
  }
}

#forgot_password_modal_form, #login_modal_form, #register_modal{
  button{
    @media screen and (max-width: 767px){
      font-size:15px;
      padding: 13px;

    }

    @media screen and (max-width: 500px) {
      font-size: 13px;
      padding: 10px;
    }
  }

  .button-exclusive{
    @media screen and (max-width: 500px) {
      font-size: 13px;
      padding: 10px;

    }
  }
}


#order-confirmation, #module-dotpay-payment, #module-cashondelivery-validation , #module-dotpay-back, .authentication, #password{
  .page-heading{
    padding-left:20px;
    padding-right:20px;
  }

  .alert{
    margin-left: 20px;
    margin-right: 20px;
  }

  .box{
    border:none;
    background: white;
  }

  #center_column {
    >p{
      margin-left: 20px;
      margin-right: 20px;

      @media (max-width:400px){
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
}

#authentication{
  .form-control{
    max-width: none;
    width: 100%;
    height: auto;
    border-radius: 4px;
    background-color: #f6f5f5;
    border: none;
    padding: 17px 27px;
    -webkit-appearance: none;
  }

  .form-group{
    margin-bottom: 15px;
    @media screen and (min-width: 991px){
      width: 350px;
    }

    label{
      display: none;
    }
  }

  .form-header{
    color: #4A4032;
    font-weight: bold;
    display: inline-block;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

#module-dotpay-payment{
  .dotpay-form-label{
    input[type=checkbox]{
      width: 20px;
      height: 15px;
      margin: 0;
      margin-left: 5px;
      margin-right: 10px;
    }
  }
}

.fancybox-overlay{

  .fancybox-close{
    display: none;
  }

  .page-subheading{
    font-family: 'Centrale Sans Rounded';
    weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #4a4032;
  }

  #send_friend_form_error{
    font-family: 'Muli';
    font-weight: 400;
    font-size:13px;
    color: #777777;
  }
}


.fancybox-skin .submit .button{
  @extend .normal-button;
  @extend .normal-button.small;
}

.conditionsCondition{
  display: flex;
  align-items:flex-start;
  justify-content: flex-start;
}

#addresses-container{
  >.checkbox:not(.addressesAreEquals){
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.sortPagiBar{
  &_column{
    border-left: 1px solid rgb(237,231,222);

    @media (max-width:768px){
      border-bottom: 1px solid rgb(237,231,222);

    }
    label{
      margin: 0;
    }

    ul{
      margin-bottom: 0;
      display: flex;
    }
  }

  &_left-column{
    display: flex;

    @media (max-width: 768px){
      padding-left: 15px;
    }
  }

  &_row{
    display: flex;
    justify-content: flex-end;
    box-shadow:0 1px 24px -10px #9c6c24;
    z-index: 1;
    position: relative;

    @media (max-width: 768px){
      flex-wrap:wrap;

    }

    ul.list-or-grid{
      @media (max-width: 768px){
        border-right: none;
        width: auto;

      }
    }

    #pagination{
      @media (max-width: 768px) {
        width: auto;
        padding: 0 15px;
      }
    }

    .top-pagination-content{
      @media (max-width: 768px) {
        padding-left:15px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  &_sort{
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    margin-right: 15px;

    @media (max-width: 768px) {
      padding: 10px 0;
    }
      >label{
      display: flex;
      align-items: center;
      flex:0 1 0;
      margin-right: 15px;


    }

    form#productsSortForm{
      flex: 1 1 0;
      padding-top: 0;
    }
  }

  &_quantity{
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    border-left: 1px solid rgb(237,231,222);
    padding-left: 15px;

   .selector1 > label{
      display: inline-block;
      margin-right: 15px;
    }

    .form-control{
        flex: 1 0 0;
    }

    .nbrItemPage{
      flex: 1 1 0;
    }
  }

  .selector1{
    display: flex;
    align-items: center;
    height: 100%;
  }

  #nb_page_items{
    border: 0;
    font-weight: 900;
    background: none;
    color: #C2AF93;
    -webkit-appearance: none;
  }

}

.nb_page_items__bottom-description{
  display: none;
}

#product_comments_block_tab{
  padding: 0 20px;
}

#categoryNaviBottom{
  display: block;
  float: left;
  width: 100%;
}

#pagination_bottom {
  display: block;
  border-top: 1px solid rgb(237,231,222);
  margin-top: -1px;

  i {
    color: #C2AF93;
  }

  ul.pagination {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    padding-top: 5px;

    .pagination_previous {
      text-align: right;
    }
  }
}

#search {
  #pagination_bottom {
    display: block;
    border-top: 1px solid rgb(237, 231, 222);
    margin-top: -1px;
    overflow: hidden;
  }
}

#layered_block_left > div.layertoggle > span:after,
#layered_block_right i.icon-caret-down:before,
.appearance-down::before{
  @media (max-width: 420px) {
    display: none;
  }
}

#layered_block_left > div.layertoggle > span {
  height: 45px;
  @media (max-width: 420px) {
    padding-left: 0;
  }
}

.fancybox-error {
  text-align: center;
  white-space: normal;
}

// Ceneo widget
@media only screen and (max-width: 825px) {
  #ceneo-widget {
    display: none!important;
  }
}

// Order message
.js-order-message {
  max-width: none;
  width: 100%;
  height: auto;
  border-radius: 4px;
  background-color: #f6f5f5;
  border: none;
  padding: 17px 27px;
}
.your-order__footer {
  margin-bottom: 20px;
}
.your-order__footer-message {
  margin-bottom: 20px;
  .js-order-message {
    margin-top: 0;
  }
  label {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

#center_column #order-detail-content {
  border-bottom: 0;
  margin-bottom: 20px;
  padding-bottom: 0;
}

// Tpay
#module-tpay-validation {
  #page input[type=checkbox] {
    visibility: visible;
    display: inline-block;
  }

  #page input[type=checkbox]::after,
  #page input[type=checkbox]:checked::after {
    content: "";
  }
}

// Product
#product {
  .reductions {
    text-align: center;
    #reduction_percent, #reduction_amount {
      display: inline-block;
      font-size: 14px;
      padding: 0 5px;
      #reduction_amount_display {
        float: none;
      }
    }
  }
}
