
.map-addresses-modal {
  top: 0;

  align-items: center;
  justify-content: center;

  &__container {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 15px;
    background-color: white;

    @media screen and (max-width: 550px){
      padding: 15px 5px;
    }
  }

  &__map {
    width: 500px;
    height: 500px;

    @media screen and (max-width: 550px){
      width: 300px;
      height:300px;
    }
  }

  &__content {
    padding: 20px;
  }

  &__save-button-container{
    width:100%;
    display: flex;
    justify-content:center;
    align-items:center;

    .normal-button{
      font-size: 14px;
      padding: 10px 30px;
    }

  }
}

.addresses{

  padding-bottom: 20px;
  &__list-item{

    &.is-hover{
      .addresses__label{
        color:black;
      }
    }

    &.is-active{
      .addresses__label{
        color: green;
      }
    }
  }

  &__element{
    display: flex;
    align-items:flex-start;
    justify-content: flex-start;

    .checkbox-icon{
      width:20px;
      height:20px;
      margin: 0;
      padding: 0;
      display: inline-block;
      font-weight: normal;

      &:after{
        content: '\f096';
        font-family: 'FontAwesome';
        font-size: 22px;
        color: #8c8a87;
        visibility: visible;
        line-height: 22px;
      }
    }

    input:checked + .checkbox-icon{
      &:after{
        content: '\f046';
      }
    }

    input{
      display: none;
    }
  }

  &__label{
    color:#4a4032;
    cursor:pointer;
    margin-left: 15px;
    line-height: 20px;
    transition: color 0.3s, opacity 0.3s;
  }

  .addresses__input{
    margin-top: 0;
  }

  &__input{
    width: 20px;
    height: 20px;
    visibility: hidden;

    &:after{
      content: '\f096';
      font-family: 'FontAwesome';
      font-size: 22px;
      color: #8c8a87;
      visibility: visible;
    }

    &:checked{
      &:after{
        content:'\f046';
      }
    }
  }
}

.choose-delivery-options-button, {
  font-size: 14px;
  padding: 10px 30px;
  margin-top: 10px;
}